import React from 'react';
import SB_Footer from './SB_Description_Footer'
import "animate.css/animate.min.css"
import SBDStyle from './SBoardDescription.module.css'
import Cover from '../assets/dieRoboter_panel.png'
import SBoardRelatedCards from "./SBoardRelatedCards";

class SBdieRoboterDescription extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={SBDStyle.container}>
                <div style={{color: '#0f0f0f', background: '#f0f0f0'}}>
                    <div className={SBDStyle.titleContainer} style={{color: '#0f0f0f'}}>
                        <h2>dieRoboter</h2>
                        <h3>Classic Robotic Voice | Made for S-Board</h3>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <img src={Cover} className={SBDStyle.banner} style={{maxHeight: '372px', width: 'auto'}}/>
                        <div style={{height: "16px"}}/>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <p className={SBDStyle.slogan}>Analog 32-band vocoder module</p>
                        <div style={{height: "30px"}}/>
                    </div>
                    <div className={SBDStyle.cartArea}>
                        <div className={SBDStyle.cartAreaContent}>
                            <div>
                                <p style={{color: '#6f747f', fontWeight: 800}}>Proccessor</p>
                                <p style={{fontSize: '1.8rem', textTransform: 'uppercase', fontWeight: 800}}>dieRoboter</p>
                                <div className={SBDStyle.currPrice}>US $59</div>
                            </div>          
                            <div className={SBDStyle.buttonWrapper} style={{textAlign:'center'}}>
                                <p style={{paddingTop: '0.6rem'}}>Must use with S-Board!</p>
                                <button onClick={this.props.addToCart} className={SBDStyle.addToCartButton}>Add To Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={SBDStyle.titleContainer}>
                        <h2>Overview</h2>
                    </div>
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Clear, intelligible, musical</h4>
                        <p>Instead of using FFT to analyze the modulator signal like lots of the software vocoders do, which tends to sound muddy and thin, we followed the traditional design which utilizes narrow bandpass filters distributed evenly across the octaves. This results in a clear, fat, musical sound.</p>
                    </div>
                    <div style={{height: "60px"}}/>  
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Use any carrier from sidechain</h4>
                        <p>By default, dieRoboter uses its built-in noise generator for the carrier signal. But you'll most likely want to use your own carrier such as your favorite synth. To do that, simply route your synth in from the sidechain and flip the second toggle to "sc". It's that simple.</p>
                    </div>
                    <div style={{height: "60px"}}/>  
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>More than the typical robotic sound</h4>
                        <p>Of cause, dieRoboter does the robot thing extremely well, but it's way more versatile than that. Tweak the "nasal" and "format" knob to shape the frequency characteristic, adjust the "attack" and "release" to alter the envelope, layering the vocoded sound on top of the dry input... Endless possibilities to explore.</p>
                    </div>
                </div>
                <SBoardRelatedCards currentItem="dieroboter"/>

                <SB_Footer getTrial={this.props.getTrial}/>
            </div>   
        )
    }
}

export default SBdieRoboterDescription